import React, { useState, useRef, useEffect } from "react"

import { RiCloseLine } from "react-icons/ri"

export default function TextareaInput({
    name,
    value,
    label,
    placeholder,
    disabled,
    onChange,
    errors,
    category,
    className,
}) {
    
	const [isFocused, setIsFocused] = useState(false)
	const [hasContent, setHasContent] = useState((value && value.length > 0) || !!placeholder)

	function handleChange(event) {
		let value = event.target.value
		if(value.length > 0) {
			if(!hasContent) setHasContent(true)
		}
		else {
			if(hasContent && !placeholder) setHasContent(false)
		}
		onChange(event)
	}

    const textareaRef = useRef(null);
    useEffect(() => {
        textareaRef.current.style.height = "auto"
        if (textareaRef.current) {
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
      }, [value]);

	return (
<div className={
    "form-group group-textarea" + 
    (disabled ? " is-disabled" : "") + 
    (errors ? " has-error" : "") + 
    (hasContent ? " has-content" : "") + 
    (isFocused ? " is-focused" : "") + 
    (className ? " " + className : "")}>

    {label &&
        <label htmlFor={"input-" + name} className="label">{label}</label>
    }

    <textarea
        ref={textareaRef}
        placeholder={placeholder}
        id={"input-" + name}
        name={name}
        category={category}
        onChange={handleChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        value={value ?? ""}
        disabled={disabled ? disabled : false}
        rows={1}
    >
    </textarea>

    <div className="input-icon">
        {errors &&
            <RiCloseLine />
        }
    </div>

    <div className="baseline"></div>

    <div className="errors">

        {errors && errors.map((error, idx) => {
            return (
                <div key={idx} className="error-item"> {error} </div>
            )
        })}

    </div>

</div>
	)
}
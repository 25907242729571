import React from "react"
import { Link } from 'react-router-dom'

import CSSBLogo from 'assets/imgs/cssb.svg'
import 'styles/header.css'

export default function Header(props){
	return (
		<header className="header">

            {props.title ? 
            <a className="logo" style={{ display: "flex", alignItems: "center", gap: "30px", marginTop: "10px"}}
                href="https://www.cssb.fr" rel="noopener noreferrer"
            >
                <img src={CSSBLogo} alt="Logo CSSB" style={{ width: "50px", marginTop: "10px"}} />
                <span style={{letterSpacing: "-0.5px", fontSize: "22px"}}>{props.title}</span>
            </a>
            :
            <Link to="/" className="logo">
                CSSB - Central
            </Link>
            }

			<nav>
				{props.nav !== false &&
                <ul>
                    <li><Link to="/">Accueil</Link></li>
                    <li><a href="https://infos.cssb.fr/parents" target="_blank" rel="noreferrer">Informations Utiles</a></li>
                </ul>
				}
			</nav>

            {props.nav !== false &&
			<Link to="/contact" className="btn outline-btn contact-btn">
				Contact
			</Link>
            }

		</header>
	)
}
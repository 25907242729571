import Header from '../components/HomeHeader'

import HomeBG from '../assets/imgs/home-bg.jpg'

const Error404 = props => {

	return (
		<>
            <Header />
            
			<main className="home-page center flex flex-horizontal flex-center" style={{ backgroundImage: "url('" + HomeBG + "')"}}>

					<div>
                        Hum ... la page que vous cherchez n'existe pas (Erreur 404)
                    </div>
					
			</main>
		</>
	)
}

export default Error404
export default function CalEventCard({ 
    event
}) {

    const { name, start, end, loc, cie } = event

    return (
<div className="calEvent-card">

    <span className="flex flex-space-between">
        <h4 style={{margin: "0"}}>{name} - {cie}</h4>

        <span className="flex flex-space-between" style={{marginBottom: 10, gap: 30}}>
            <div>
                <strong>Début :</strong> {start}
            </div>
            <div>
                <strong>Fin :</strong> {end}
            </div>
        </span>
    </span>

    <div className="flex flex-horizontal flex-left" style={{gap: 6}}>
        <strong>Lieu :</strong> {loc}
    </div>
</div>
    )
}
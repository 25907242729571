import {
	Switch,
	Route
} from "react-router-dom";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


// Registration pages
import HomePage from 'pages';
import ContactPage from 'pages/contact';

// Common components
import VersionDisplay from 'components/VersionDisplay';
import ReturnToTop from 'components/ReturnToTop';

// Auth pages
import LoginPage from 'pages/login';
import LogoutPage from 'pages/logout';
import RequestPasswordReset from 'pages/request-password-reset';
import ResetPassword from 'pages/reset-password';
import Error404 from 'pages/error404';

// Dash pages
import DashRoute from 'core/Route';
import DashHome from "pages/dashboard"
import HelpFormular from 'pages/dashboard/help';
import Changelog from 'pages/dashboard/changelog';

// Admin Dash pages
import MyData from "pages/dashboard/my-data"
import Listing from "pages/dashboard/listing"
import Member from "components/members/Member"
import Calendar from "pages/dashboard/calendar";

import AccountList from "pages/dashboard/accounts"

// Events module
import MyEvents     from 'pages/dashboard/my-events';
import Register     from 'pages/dashboard/my-events/EID';
import Overview     from 'pages/dashboard/events';
import Event 		from "pages/dashboard/events/EID";
import EventListing from 'pages/dashboard/events/EID/list';
import Registration from 'pages/dashboard/events/EID/list/MATRICULE';

// Medic module
import MedicEvent from 'pages/dashboard/events/EID/medic';
import MedicActs  from 'pages/dashboard/events/EID/medic/acts';

// Presence module
import PresenceEvent from 'pages/dashboard/events/EID/presences';

function App() {

	return (
		<>
			<VersionDisplay />

			<Switch>

				{/* Public pages */}
			    <Route exact path="/" component={HomePage} /> 
				<Route exact path="/contact" component={ContactPage} />

				{/* Access */}
				<Route exact path="/login" component={LoginPage} />
				<Route exact path="/logout" component={LogoutPage} />
				<Route exact path="/request-password-reset" component={RequestPasswordReset} />
				<Route exact path="/reset-password/:token" component={ResetPassword} />

				{/* Main dashboard (base user modules)*/}
				<DashRoute exact path="/dashboard" component={DashHome} />
                <DashRoute exact path="/dashboard/support" component={HelpFormular} />
                <DashRoute exact path="/dashboard/changelog" component={Changelog} />

				<DashRoute exact path="/dashboard/my-data" component={MyData} />
				<DashRoute exact path="/dashboard/my-data/:matricule" component={Member}/>

                <DashRoute exact path="/dashboard/my-events" component={MyEvents} />
                <DashRoute exact path="/dashboard/my-events/:eID" component={Register} />

                {/* Listing module */}
				<DashRoute exact path="/dashboard/listing" component={Listing} />
				<DashRoute exact path="/dashboard/listing/:matricule" component={Member}/>

                {/* Calendar module */}
				<DashRoute exact path="/dashboard/calendar" component={Calendar} />

                {/* Event module */}
                <DashRoute exact path="/dashboard/events" component={Overview} />
				<DashRoute exact path="/dashboard/events/:eID" component={Event} />
                <DashRoute exact path="/dashboard/events/:eID/list" component={EventListing} />
                <DashRoute exact path="/dashboard/events/:eID/list/:matricule" component={Registration} />

                <DashRoute exact path="/dashboard/events/:eID/medic" component={MedicEvent} />
                <DashRoute exact path="/dashboard/events/:eID/medic/acts" component={MedicActs} />

                <DashRoute exact path="/dashboard/events/:eID/presences" component={PresenceEvent} />
                <DashRoute exact path="/dashboard/events/:eID/presences/:matricule" component={Member} />
                <DashRoute exact path="/dashboard/events/:eID/medic/:matricule" component={Member} />
                
				

				{/* Trombinoscope */}
				{/* <DashRoute exact path="/dashboard/trombinoscope" component={Trombinoscope} /> */}
                

				{/* Account module (only DEV access for now)*/}
				<DashRoute exact path="/dashboard/accounts" component={AccountList} />

				{/* Undefined path */}
				<Route component={Error404} />
					
			</Switch>

            <ReturnToTop />

			<ToastContainer />

		</>
	);
}

export default App;

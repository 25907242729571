import axios from 'axios'
import React from "react"
import { useLocation } from "react-router-dom";

axios.interceptors.response.use(function(response) {
	const localVersion = window.localStorage.getItem("version");

	if (response.headers['x-version'] && response.headers['x-version'] !== localVersion) {
		if (localVersion !== null) {
			// There was a previous version in localStorage
			window.localStorage.setItem('version-update-needed', 'true');
			console.log("Need update !!!")
		}
		console.log("Storing version in localStorage")
		// In any case, set current server version in localStorage
		window.localStorage.setItem('version', response.headers['x-version']);
	}
	return response;
  });


function VersionCheck() {

	// check if a version update (refresh) needed on route change
	let location = useLocation();
	React.useLayoutEffect(() => {
	  // if there is an update available and no state passed to route
	  if (!location.state && window.localStorage.getItem('version-update-needed')) {
        window.localStorage.removeItem('version-update-needed')
        window.localStorage.removeItem('tips')
        window.sessionStorage.clear();
		if (caches) {
			// Service worker cache should be cleared with caches.delete()
			caches.keys().then(function(names) {
			  for (let name of names) caches.delete(name);
			});
		}
		window.location.reload(true); // refresh the browser
		// TODO : add some particular screen to alert about an app update and a hard reload ...
	  }
	}, [location]);

	return null;
  };

function VersionDisplay() {

	const version = process.env.NODE_ENV === "development" ? "dev" : localStorage.getItem("version")

	return (
<>
	<VersionCheck />
	<div className="version-display">Version : {version}</div>
</>
	)
}

export default VersionDisplay
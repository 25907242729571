import React , { useState, useEffect } from "react"
import axios from "axios"
import { toast } from "react-toastify"

import ConfirmPopup from 'components/ConfirmPopup'
import DropdownMenu from 'components/DropdownMenu'
import IconButton from 'components/IconButton'
import FullPageLoading from 'components/FullPageLoading'
import { ReactComponent as ArrowDownIcon } from 'assets/icons/down-arrow.svg'

export default function OptionsDisplay({ 
    member,
    fID,
    options=[], 
    scope=1,
    onUpdate,
}) {
    const [label, setLabel] = useState("???")

    const [modal, setModal] = useState("")
    const [newOption, setNewOption] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    function setupChange(option) {
        if (option) {
            setModal("confirmChange")
            setNewOption(option)
        } else {
            setModal("")
            setNewOption(null)
        }
    }

    function updateOption() {
        if (!newOption) return;
        setupChange(null)
        setIsLoading(true)
        axios.put(`/api/members/${member.matricule}`,{
            "fields": {[fID]: newOption.value}
        }).then(() => {
            member[fID] = newOption.value
            setLabel(newOption.label)
            toast.success(`Mise-à-jour effectuée (${fID})`)
            if (onUpdate) onUpdate()
        }).catch(err => {
            if (err?.response?.data.detail) {
                toast.error(err.response.data.detail)
            } else {
                toast.error("Erreur bizarre ...")
                console.log(err)
            }
        }).finally(() => {
            setIsLoading(false)
        })
    }

    useEffect(() => {
        const option = options.find(obj => obj.value === member[fID])
        if (option) setLabel(option.label)
    }, [fID, member, options])

    return (
<>
    {scope > 1 ?
        <>
        <FullPageLoading show={isLoading} />
        {modal === "confirmChange" &&
            <ConfirmPopup 
                title="Êtes vous vraiment sûr de ce changement ?    " 
                onConfirm={updateOption} 
                onReject={() => setModal("")} 
            />
        }
        <DropdownMenu
        header={() => <IconButton 
                        icon={<ArrowDownIcon />} 
                        side="right" 
                        label={label} 
                        onClick={() => {}} />}
        content={() => {
                return (
                    options.map(option => {
                        return <div key={option.value} 
                                    onClick={
                                        () => {option.value !== member[fID] && setupChange(option)}
                                    }
                                >
                                    {option.label}
                                </div>
                    })
                )
            }}
        />
        </>
        :
        <>
            {label}<br/>
        </>
    }
</>
    )
}
import React, { useState } from 'react';
import axios from 'axios';

import TextInput from 'components/Inputs/OldTextInput';
import DateInput from 'components/Inputs/OldDateInput';
import SelectInput from 'components/Inputs/OldSelectInput';

import FullPageLoading from "components/FullPageLoading"

import Modal from "components/Modal"
import { toast } from 'react-toastify';

export default function CreateMember({
    addRights,
    onSuccess,
}) {
    const cies = addRights.cies ?? []
    const memberTypes = addRights.memberTypes ?? []

    const [showCreateMember, setShowCreateMember] = useState(false);
    const [memberData, setMemberData] = useState({});

    const [loading, setLoading] = useState(false)
    
    function handleInputChange(name, value){
        setMemberData({...memberData, [name]: value});
    };
    
    function handleSubmit(){
        setLoading(true)
        axios.post("api/members/new", 
            {"data": memberData}
        ).then(res => {
            toast.success("Le membre a été créé avec succès")
            setShowCreateMember(false)
            setMemberData({})
            if (onSuccess) onSuccess()
        }).catch(err => {
            if (err?.response?.data.detail) {
				toast.error(JSON.stringify(err.response.data.detail))
			} else {
				toast.error("Erreur bizarre ...")
				console.log("erreur", err)
			}
        }).finally(() => {
            setLoading(false)
        })
    };

    if (cies.length === 0 && memberTypes.length === 0) { return null }
    
    return (
<>
    <FullPageLoading show={loading} />
    
    <div className="btn bold" style={{width: "fit-content"}} onClick={() => setShowCreateMember(true)}>
        + Nouveau
    </div>
    
    <Modal fixed={true} className="confirm-modal" show={showCreateMember} onClose={() => setShowCreateMember(false)}>

        <div className="form-title title mb-30">
            Créer un nouveau membre
        </div>

        <form>

            <div className="mb-30 flex flex-horizontal flex-space-around">
                <TextInput
                    className="mr-15"
                    label="Prénom"
                    name={"firstName"}
                    value={memberData["firstName"] ?? ""}
                    onChange={e => handleInputChange("firstName", e.target.value)}
                />

                <TextInput
                    label="Nom de Famille" 
                    name={"lastName"}
                    value={memberData["lastName"] ?? ""}
                    onChange={e => handleInputChange("lastName", e.target.value)}
                />
            </div>

            <div className="mb-30 flex flex-horizontal flex-space-around">
                    <SelectInput
                        label="Compagnie"
                        name="company"
                        options={cies}
                        value={memberData["company"] ?? undefined}
                        onChange={e => handleInputChange("company", e.target.value)}
                    />
                    <SelectInput
                        label="Type de Membre"
                        name="memberType"
                        options={memberTypes}
                        value={memberData["memberType"] ?? undefined}
                        onChange={e => handleInputChange("memberType", e.target.value)}
                    />
            </div>

            <div className="mb-30 flex flex-horizontal flex-space-around">
                <TextInput
                    className="mr-15"
                    label="Email"
                    name="email" value={memberData["email"] ?? ""}
                    onChange={e => handleInputChange("email", e.target.value)}
                />
                <DateInput
                    label="Date de naissance"
                    name={"birthDate"}
                    value={memberData["birthDate"] ?? null}
                    onChange={e => handleInputChange("birthDate", e.target.value)}
                />

                
            </div>

        </form>


        <div className="flex center gap-20 flex-horizontal">
            <div className="pointer blue-link" onClick={() => setShowCreateMember(false)}>Annuler</div>
            <div className="btn" onClick={handleSubmit}>Confirmer</div>
        </div>

    </Modal>
</>
    );
};
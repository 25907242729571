import React, { useState, useEffect } from "react";
import axios from "axios";

import DashHeader from "components/common/DashHeader";
import DashSidebar from "components/common/Sidebar";

import Table from "components/Table"
import { Collapsible } from "components/utils/dev";


function AccountList() {
    const [accounts, setAccounts] = useState([]);

    useEffect(() => {
        const fetchAccounts = async () => {
            const response = await axios.get("/api/accounts/list");
            setAccounts(response.data);
        };
        fetchAccounts();
    }, []);

    const allProfiles = [...new Set(accounts.map(account => account.profiles))]

    return (
<div className="admin-page">

<DashSidebar current="accounts" />

<DashHeader paths={[
    {
        name: "Comptes Utilisateurs",
        url: "/dashboard/accounts"
    }
]} />

<div className="admin-body">

    <Table
        uniqueID="accounts"
        headers={[
            {
                label: "Email",
                key: "email"
            },
            {
                label: "Matricules",
                key: "matricules",
                formatFunc: (matricules) => matricules.join(", ")
            },
            {
                label: "Profiles",
                key: "profiles"
            },
            {
                label: "Dernière Connection",
                key: "lastConnect"
            },
        ]}
        searchKeys={["email", "matricules", "lastConnect"]}
        filters={[{
            "key": "profiles",
            "label": "Profiles",
            "options": allProfiles.map(value => ({name:value, value:value}))
        }]}
        dataName="compte"
        data={accounts}
    />

    {/* For development and debug ... */}
    <Collapsible header="accounts" data={accounts} />

</div>

</div>
    );
};

export default AccountList;

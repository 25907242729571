import axios from 'axios'
import React, { useState } from "react"
import { toast } from 'react-toastify'
import FullPageLoading from 'components/FullPageLoading'

import Header from "components/HomeHeader"
// TODO : adapt to new text input
import TextInput from 'components/Inputs/OldTextInput'

export default function RequestResetPassword() {

	const [isLoading, setIsLoading] = useState(false)
	const [success, setSuccess] = useState("")
	const [email, setEmail] = useState("")

	function handleSubmit(event) {
		event.preventDefault()

		// TODO : add some test for the email format

		setIsLoading(true)

		axios.post("/api/auth/request-password-reset", 
			{ email: email }
		).then(() => {
			setSuccess("mouiii")
		}).catch(err => {
			if (err?.response?.data?.detail) {
				toast.error(err.response.data.detail)
			} else {
				toast.error("Une erreur est survenue")
				console.log(err)
			}
		}).finally(() => setIsLoading(false))
	}

	return (
		<>
			<Header nav={false} />

			<FullPageLoading show={isLoading} />

			<div className="small-form">

				<div className="full-page-card">

					<div className="blue-title">(Ré-)initialisation du mot de passe</div>

					<div className="card-content">

						{success.length === 0 &&
							<form className="login-form" onSubmit={handleSubmit}>

								<div className="infos-box" id="hint">
								Entrer l'adresse mail de votre compte
								</div>

								<TextInput 
									className="ml-15" 
									label="Email" 
									name="email" 
									value={email} 
									onChange={e => setEmail(e.target.value)} 
								/>

								<button className="btn" type="submit">Envoyer la demande</button>

							</form>
						}

						{success.length > 0 &&
							<div>
								Votre demande a été prise en compte pour l'adresse :<br></br>
								<pre>{email}</pre>
								<div className="infos-box">
									Si cette adresse mail est bien associée à un compte, 
									vous devriez recevoir un email sous peu avec un lien 
									pour entrer un nouveau mot de passe.
								</div>
								<br></br>
								Vous pouvez fermer cette fenêtre ...
							</div>
						}

					</div>

				</div>

			</div>

		</>
	)
}
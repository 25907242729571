import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify"

import DashHeader from 'components/common/DashHeader'
import DashSidebar from 'components/common/Sidebar'

import FullPageLoading from "components/FullPageLoading"
import { Collapsible } from "components/utils/dev"
import BodyHeader from "components/common/BodyHeader";

import CalEventCard from "components/calendar/CalCard";

import TextInput from "components/Inputs/OldTextInput";
import MultipleSelectInput from "components/Inputs/MultipleSelect";
import IconButton from "components/IconButton";
import { FilterIcon } from "icons";

export default function Calendar({
    history
}) {

    const [isLoading, setIsLoading] = useState(false)

    const [activities, setActivities] = useState([])
    const [searchKeys, setSearchKeys] = useState([])
    const [filters, setFilters] = useState([])

    const [search, setSearch] = useState("")
    function findInList(search, terms) {
        search = search.toLowerCase()
        for (const term of terms) {
            if (term && String(term).toLowerCase().includes(search)) return true
        }
        return false
    }
    const [showOptions, setShowOptions] = useState("")
    const [filterLists, setFilterLists] = useState({})
    function toggleOptions(event, key) {
        event.stopPropagation()
        if (showOptions === key) {
            setShowOptions("")
        } else {
            setShowOptions(key)
        }
    }
    function selectFilters(key, value) {
        const newFilterLists = {...filterLists, [key]: value}
        setFilterLists(newFilterLists)
    }
    
    const filteredActivities = activities.filter(row => {
        for (const {key} of filters) {
            if (filterLists[key] && filterLists[key].length > 0) {
                if (!filterLists[key].includes(row[key])) return false
            }
        }
        if (search) {
            let terms = searchKeys.map(key => row[key])
            if (!findInList(search, terms))
                return false
        }
        return true
    })

    const nActivities = filteredActivities.length;

    useEffect(() => {
        setIsLoading(true)
        axios.get("/api/companies/calendar" 
        ).then(res => {
            setActivities(res.data.activities);
            setFilters(res.data.filters);
            setSearchKeys(res.data.searchKeys)
        }).catch(error => {
            if (error?.response?.data.detail) {
				toast.error(JSON.stringify(error.response.data.detail))
			} else {
				toast.error("Erreur bizarre ...")
				console.log("erreur", error)
			}
        }).finally(() => setIsLoading(false))
    }, []);

    useEffect(() => {
        // When clicking out of the MultipleSelect choices, simply close all
        function closeOptions(event) {
			if (!event.target.closest(".multiple-select") && !["path", "svg"].includes(event.target.nodeName.toLowerCase())) 
                setShowOptions("")
		}
		window.addEventListener("click", closeOptions)
		return function () {
			window.removeEventListener("click", closeOptions)
		}
	}, [])

    const past = filteredActivities.filter(a => (a.tLoc === "past"))
    const futur = filteredActivities.filter(a => (a.tLoc === "futur"))
    const ongoing = filteredActivities.filter(a => (a.tLoc === "ongoing"))

	return (
		<div className="admin-page">

            <FullPageLoading show={isLoading}/>

			<DashSidebar current="calendar" />
			<DashHeader paths={[{ name: "Calendrier", url: "/dashboard/calendar" }]} />

			<div className="admin-body">

                <BodyHeader 
                    id={"calendar"} 
                    title={"Liste des activités vous concernant"}>
                <section className="member-basic">
                    <div className="info-section">
                        Données récupérées directement depuis les Google Agenda des commandants de compagnie (prend un peu de temps ...).<br/>
                        En cas d'informations manquantes, voir directement avec le commandant concerné pour qu'il les ajoute ...<br/><br/>
                        {filters.length > 0 && filters[0].options.length > 1 && 
                        <>
                            Utiliser la barre de recherche pour filtrer les activités selon le mois, le lieu, etc ... ou encore le filtre de compagnies.<br/>
                        </>}
                        ⚠️ Ces informations peuvent changer si votre commandant les mets à jour durant l'année<br/>
                        ➡️ N'hésitez pas à consulter cette page régulièrement ...
                    </div>
                </section>
                </BodyHeader>

                <div className="user-action">
                    <TextInput 
                        label="Rechercher" 
                        value={search ?? ""}
                        baselineNoMargin
                        onChange={(event => setSearch(event.target.value))} />
                    <div> {nActivities} Activité{nActivities > 1 ? "s" : ""}</div>
                    {filters.map(({key, label, options}) => {
                        if (options.length < 2) return null;
                        return (
                            <div key={`filter-${key}`}>
                                <IconButton 
                                    icon={<FilterIcon />} 
                                    label={label}
                                    onClick={(e) => toggleOptions(e, key)}
                                    active={filterLists[key] && filterLists[key].length > 0}
                                />
                                <MultipleSelectInput
                                    show={showOptions === key}
                                    options={options}
                                    value={filterLists[key] ?? undefined}
                                    onChange={e => {selectFilters(key, e.target.value)}}
                                />
                            </div>
                        )
                    })}
                </div>

                {ongoing.length > 0 && <h4> En cours : </h4>}
                {ongoing.map(event => (<CalEventCard event={event}/>))}

                {futur.length > 0 && <h4> À venir : </h4>}
                {futur.map(event => (<CalEventCard event={event}/>))}

                {past.length > 0 && <h4> Passés : </h4>}
                {past.map(event => (<CalEventCard event={event}/>))}

                {/* For development and debug ... */}
                <Collapsible header="events" data={activities} />

			</div>

			

		</div>
	)
}
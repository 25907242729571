import { useState } from "react"
import storage from "core/utils/storage"

export default function BodyHeader({ children, title, id, buttons }) {

    const [hideTips, setHideTips] = useState(storage.get(`tips.${id}`))

    function toggleTips() {
        const newTipsNeeded = !hideTips
        setHideTips(newTipsNeeded)
        const tips = storage.get("tips", {})
        tips[id] = newTipsNeeded
        storage.set("tips", tips)
    }

    return (
        <div className="body-header">
            <span>
                <h4 style={{width: "fit-content"}}>{title}</h4>
                <div className="buttons">
                    {children && hideTips && <span className="blue-link bold" onClick={toggleTips}>Plus d'informations ...</span>}
                    {buttons}
                </div>
            </span>
            {!hideTips && (
                <div className="tips-frame">
                    <span className="tips-text">
                        {children}
                    </span>
                    <span className="blue-link tips-close-btn bold" style={{fontSize: "0.9em"}} onClick={() => toggleTips()}>Bien compris !</span>
                </div>
            )}
        </div>
    )
}
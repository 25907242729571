import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify"

import DashHeader from 'components/common/DashHeader'
import DashSidebar from 'components/common/Sidebar'

import Table from "components/Table";
import EventCard from "components/events/Card";
import { EventButtons } from "components/events/Buttons";

import FullPageLoading from "components/FullPageLoading"
import { Collapsible } from "components/utils/dev"
import BodyHeader from "components/common/BodyHeader";

export default function Overview({
    history
}) {

    const [isLoading, setIsLoading] = useState(false)
    const [headers, setHeaders] = useState()
    const [events, setEvents] = useState()
    const [activeEvents, setActiveEvents] = useState()
    const [closedEvents, setClosedEvents] = useState()

    useEffect(() => {
        setIsLoading(true)
        axios.get("/api/events/overview" 
        ).then(res => {
            setHeaders(res.data.headers);
            setEvents(res.data.events);
            setActiveEvents(res.data.events.filter(event => event.active));
            setClosedEvents(res.data.events.filter(event => !event.active));
        }).catch(error => {
            if (error?.response?.data.detail) {
				toast.error(JSON.stringify(error.response.data.detail))
			} else {
				toast.error("Erreur bizarre ...")
				console.log("erreur", error)
			}
        }).finally(() => setIsLoading(false))
    }, []);

	return (
		<div className="admin-page">

            <FullPageLoading show={isLoading}/>

			<DashSidebar current="events" />
			<DashHeader paths={[{ name: "Évènements", url: "/dashboard/events" }]} />

			<div className="admin-body">

                {headers && activeEvents &&
                    <>
                        <BodyHeader 
                            id={"events"} 
                            title={"Liste des inscriptions ouvertes :"}>
                        <section className="member-basic">
                            <div className="info-section">
                                Cliquez sur le titre de la vignette pour accéder à la page descriptive de l'évènement, contenant :
                                <ul style={{marginTop: "5px"}}>
                                    <li>les informations sur les tarifs et options de paiements</li>
                                    <li>les trombinoscopes par compagnie</li>
                                    <li>la liste des participants, etc ...</li>
                                </ul>
                                Vous pouvez aussi accéder directement à la liste des participants, etc ... avec les boutons correspondant
                            </div>
                        </section>
                        </BodyHeader>
                        {activeEvents.map(event => (
                            <EventCard key={event.eID} event={event}>
                                <EventButtons eID={event.eID} />
                            </EventCard>
                        ))}
                        
                    </>
                }

                {headers && closedEvents &&
                    <>
                        <h4>Anciens événements (inscription fermée) :</h4>
                        <Table
                            uniqueID="my-events"
                            headers={headers}
                            data={closedEvents}
                            onItemClicked={event => history.push(`/dashboard/events/${event.eID}`)}
                        />
                    </>
                }

                {/* For development and debug ... */}
                <Collapsible header="headers" data={headers} />
                <Collapsible header="events" data={events} />

			</div>

			

		</div>
	)
}
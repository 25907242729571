import React, { useState, useEffect } from "react";
import axios from "axios";

import DashHeader from 'components/common/DashHeader'
import BodyHeader from 'components/common/BodyHeader'
import DashSidebar from 'components/common/Sidebar'

import Table from "components/Table";

import FullPageLoading from "components/FullPageLoading"
import { Collapsible } from "components/utils/dev"

export default function MyEvents({
    history
}) {

    const [isLoading, setIsLoading] = useState(false)
    const [headers, setHeaders] = useState()
    const [events, setEvents] = useState()

    useEffect(() => {
        setIsLoading(true)
        axios.get("/api/events/my-events" 
        ).then(res => {
            setHeaders(res.data.headers);
            setEvents(res.data.events);
        }).catch(err => {
            console.log("Error ", err)
        }).finally(() => setIsLoading(false))
    }, []);

	return (
		<div className="admin-page">

            <FullPageLoading show={isLoading}/>

			<DashSidebar current="my-events" />

			<DashHeader paths={[
                {
					name: "Mes Inscriptions",
					url: "/dashboard/my-events"
				},
			]} />

			<div className="admin-body">
                <BodyHeader id="my-events" title="Listes des évènements auxquels vous pouvez vous inscrire :">
                    <>
                    <section className="member-basic">
                        <div className="info-section">
                            <ul style={{marginTop: "5px"}}>
                                <li> camps d'été, stages (chefs compagnon, CSAO, ...) </li>
                                <li> adhésions à l'année dans une compagnie d'active </li>
                                <li> transports pour le camp</li>
                            </ul>
                            <strong>Important :</strong>  
                            <ul style={{marginTop: "5px"}}>
                                <li>l'inscription à certains évènements (camp ou inscription à l'année) n'est 
                                autorisée
                                ⚠️ <strong>que</strong> ⚠️ si toutes les fiches membres des personnes pouvant s'inscrire sont complètes, 
                                mises-à-jour, et validées ... (status OK dans "Mes Données")</li>
                                <li>vous ne verrez rien s'il n'y a aucun évènement actif auquel vous pouvez vous inscrire personnellement<br></br>
                                ➡️ pour les secrétaires, voir l'onglet <strong>Évènements</strong> pour le suivi des inscriptions sur votre compagnie
                                </li>
                            </ul>
                            <em>Cliquez sur la ligne correspondante dans le tableau ci-dessous pour accéder à la page de l'évènement ...</em>
                        </div>
                    </section>
                    </>
                </BodyHeader>

                {headers && events &&
                <Table
                    uniqueID="my-events"
                    headers={headers}
                    data={events}
                    onItemClicked={event => history.push(`/dashboard/my-events/${event.eID}`)}
                />}

                {/* For development and debug ... */}
                <Collapsible header="headers" data={headers} />
                <Collapsible header="events" data={events} />

			</div>

			

		</div>
	)
}
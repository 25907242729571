import React, { useState } from "react"
import axios from "axios"
import { toast } from "react-toastify"
import { useHistory } from 'react-router-dom';

import FullPageLoading from 'components/FullPageLoading'

function EventButtons({ eID }) {
    // Get user scopes
    const { profiles } = JSON.parse(localStorage.getItem("user"))
    const history = useHistory();
    const isStaff = profiles.includes("DEV") || profiles.some(elt => elt.startsWith("STAFF"))


    return (
<>
    {(isStaff || profiles.some(elt => elt.startsWith("ADMIN"))) &&
        <button className="btn" onClick={() => history.push(`/dashboard/events/${eID}/list`)}>Participants</button>
    }
    {isStaff &&
        <button className="btn" onClick={() => history.push(`/dashboard/events/${eID}/presences`)}>Présences</button>
    }
    {(isStaff || profiles.includes("MEDIC")) &&
        <button className="btn" onClick={() => history.push(`/dashboard/events/${eID}/medic`)}>Suivi Médical</button>
    }

</>
    )
}

function TrombiButtons({ event }) {

    const [isLoading, setIsLoading] = useState(false)

    const cies = event?.openTo?.company ?? []

    function getTrombi(cie) {
        setIsLoading(true)
        axios.get(`/api/members/trombinoscope/${event.eID}/${cie}`, { 
            responseType: "blob" 
        }).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `trombinoscope_${cie}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        }).catch(err => {
            toast.error("Erreur lors du téléchargement")
            console.log(err)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    if (!["activity", "camp"].includes(event.eType)) {
        return null
    }
    if (!cies.length) {
        
        return null
    }

    function cieName(cie) {
        if (cie === 1) return "1ère Cie"
        return `${cie}ème Cie`
    }

    return (
<>
    <FullPageLoading show={isLoading} />

    <h4>Trombinoscopes</h4>

    <span className="margin-auto flex" style={{gap: 6}}>
        {cies.map((cie, idx) =>
            <button key={idx} className="btn" onClick={() => getTrombi(cie)}>{cieName(cie)}</button>
        )}
        
    </span>

</>
    )
}

export {
    EventButtons,
    TrombiButtons,
}
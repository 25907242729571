// In App.js, all the routes are wrapped in this component, DashRoute
// This component is responsible for checking if the user is logged in or not. 
// If the user is logged in, it will render the children, otherwise it will redirect to the login page.

import React, { useEffect, useState } from "react"
import { Route, Redirect } from 'react-router'
import Cookies from "js-cookie"
import AuthService from "./services/AuthService"


function DashRoute({component: Component, ...rest}) {
    
    const [redirect, setRedirect] = useState(false)
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		async function initData() {
			await AuthService.refreshToken().catch(err => {
				console.log(err)
				window.location.href = `/login?redirect=${window.location.pathname}&error=${err.message}`
			})

			if(!Cookies.get("access_token")) {
				setRedirect(true)
			}

			setLoading(false)
		}
		initData()
	}, [])

	return (
		<Route
		  {...rest}
		  render={(props) =>
			loading ? (
				<div>Chargement en cours...</div>
			) : (
				redirect ? <Redirect to="/dashboard" /> : <Component {...rest} {...props} />
			)
		}
		/>
	  );
}

export default DashRoute
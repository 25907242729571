import React, { useCallback, useEffect, useState } from "react"
import axios from "axios"
import { useParams } from 'react-router'
import { toast } from "react-toastify"


import FullPageLoading from 'components/FullPageLoading'

import DashHeader from "components/common/DashHeader"
import DashSidebar from "components/common/Sidebar"
import PaymentMode from "components/events/PaymentModes"

import { EventButtons, TrombiButtons } from "components/events/Buttons"

import { Collapsible } from "components/utils/dev"

export default function Event({
    path,   // Note : path and history implicitly transmitted through the <DashRoute> component
}) {
    const { eID } = useParams()
    const from = path.split("/")[2]

    const [isLoading, setIsLoading] = useState(false)

    const [event, setEvent] = useState({})
    const [members, setMembers] = useState([])

    const [totalHA, setTotalHA] = useState()

    const loadEvent = useCallback(() => {
        setIsLoading(true)
        axios.get(`/api/events/${eID}`).then(res => {
            setEvent(res.data.event)
            setMembers(res.data.members);
        }).catch(err => {
            if (err?.response?.data?.detail)
                toast.error(err.response.data.detail)
            else {
                toast.error("Erreur bizarre")
                console.log(err)
            }
        }).finally(() => {
            setIsLoading(false)
        })
    }, [eID])

    const loadHelloAssoInfos = useCallback(() => {
        axios.get(`/api/helloasso/infos/${eID}`).then(res => {
            setTotalHA(res.data.total)
        }).catch(err => {
            if (err?.response?.data?.detail)
                toast.error(err.response.data.detail)
            else {
                toast.error("Erreur bizarre")
                console.log(err)
            }
        })

    }, [eID])

    useEffect(() => {
        loadEvent(); loadHelloAssoInfos();
    }, [loadEvent, loadHelloAssoInfos])

    const sep = "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"
    var noHelloAssoPayments = 0
    if (event.totals?.check) noHelloAssoPayments += event.totals.check
    if (event.totals?.vacationCheck) noHelloAssoPayments += event.totals.vacationCheck
    if (event.totals?.sponsor) noHelloAssoPayments += event.totals.sponsor

    const strTotalHA = totalHA ? `(${totalHA} sur le site)` : ""

    return (
<>

<FullPageLoading show={isLoading} />

<div className="admin-page">

    <DashSidebar current={from} />

    <DashHeader paths={[
        {
            name: "Évènements",
            url: `/dashboard/${from}`
        },
        {
            name: event?.title,
            url: `/dashboard/${from}/${eID}`
        },
    ]} />

    <div className="admin-body">

        {event.totals &&
            <div className="admin-section">
                Total des paiements : {event.totals.all.toFixed(2)} {sep} (hors HelloAsso : {noHelloAssoPayments.toFixed(2)})<br/>
                {event.totals.helloAsso ? 
                    `HelloAsso : ${event.totals.helloAsso} ${strTotalHA}${sep}` : ""}
                {event.totals.check ? 
                    `Chèques : ${event.totals.check}${sep}` : ""}
                {event.totals.vacationCheck ? 
                    `Chèques vacance: ${event.totals.vacationCheck}${sep}` : ""}
                {event.totals.sponsor ? 
                    `Parrainage : ${event.totals.sponsor.toFixed(2)}${sep}` : ""}
            </div>
        }
        <span className="margin-auto flex" style={{gap: 6}}>
            <EventButtons eID={eID} />
        </span>

        <TrombiButtons event={event} />

        <h4>Tarifs et types de paiement</h4>
        <div className="paymentMode">
            {event.rates && event.rates.length > 0 &&
                <div className="admin-section">
                    Prix de l'évènement
                    <ul>
                        {event.rates.map((rate, idx) => 
                            <li key={idx}> {rate.label} : {rate.amount}€ <br/><em>{rate.descr}</em></li>
                        )}
                    </ul>
                </div>
            }

            {event.options && event.options?.length !== 0 &&
                <div className="admin-section">
                    Option{event.options.length > 1 ? "s": ""} proposée{event.options.length > 1 ? "s": ""} :
                    <ul>
                        {event.options.map((option, idx) => 
                            <li key={idx}> {option.label} : {event.rates.length > 0 ? "+": ""}{option.amount}€ </li>
                        )}
                    </ul>
                </div>
            }

            {event.discounts && event.discounts?.length !== 0 &&
                <div className="admin-section">
                    Reduction(s) possible(s) (par personne inscrite):
                    <ul>
                        {event.discounts.map((discount, idx) => 
                            <li key={idx}> {discount.label} : -{discount.amount}€ </li>
                        )}
                    </ul>
                </div>
            }
        </div>

        {event.paymentModes && event.paymentModes.length !== 0 &&
            <div className="admin-section">
                
                {Object.entries(event.paymentModes).map(([mode, data]) => 
                    <PaymentMode key={mode} 
                        data={{...data, mode:mode}}
                    />
                )}
            </div>
        }


        {/* For development and debug ... */}
        <Collapsible header="event" data={event} />
        <Collapsible header="members" data={members} />
    </div>

</div>
</>
    )
}
function get(key, defVal=false) {
    let keys = key.split(".")
    let obj = JSON.parse(localStorage.getItem(keys[0])) ?? {}
    for (let i = 1; i < keys.length; i++) {
        if (keys[i] in obj) {
            obj = obj[keys[i]]
        } else {
            return defVal
        }
    }
    return obj
}

function set(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
}

const exportObj = {
    get,
    set
}

export default exportObj
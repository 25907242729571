import Header from 'components/HomeHeader'

import HomeBG from 'assets/imgs/home-bg.jpg'
import CSSBLogo from 'assets/imgs/cssb.svg'

const HomePage = props => {

	return (
		<>
			<Header />

			<main className="home-page center flex flex-horizontal flex-center" style={{ backgroundImage: "url('" + HomeBG + "')"}}>


					<img src={CSSBLogo} alt="Logo CSSB" className="no-mobile" style={{ height: "50vh", maxHeight: "450px" }}/>

					<div className="vertical-line mr-45 ml-45 no-mobile" style={{ height: "60vh" }}></div>

					<div>
					<button className="home-btn" onClick={() => window.location.href = "/login"}>
						Se connecter <div className="icon">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" style={{color: "var(--redCSSB)"}}><path fill="none" d="M0 0h24v24H0z"></path><path fill="currentColor" d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"></path></svg>
  </div>
					</button>
					</div>
					
			</main>
		</>
	)
}

export default HomePage
import { ReactComponent as FireIcon } from "assets/icons/fire.svg"
import { ReactComponent as CheckIcon } from "assets/icons/small-check.svg"
import { ReactComponent as WarningIcon } from "assets/icons/warning.svg"
import { ReactComponent as RegisteredIcon } from "assets/icons/principal-tutor.svg"

export default function EventCard({ 
    children, 
    event 
}) {

    const { eID, title, registered, payments, collected } = event

    return (
<div className="event-card">
    <span className="flex flex-space-between" style={{cursor: "pointer"}} onClick={() => window.location.href = `events/${eID}`}>
        <h4 style={{margin: "0"}}>{title}</h4>

        <span className="flex flex-space-between" style={{marginBottom: 10, gap: 10}}>
            <div className={"flex account-status status-1 margin-sides-10"}>
                <div className="icon"><RegisteredIcon/></div>
                <div className="label">Inscrits : {registered}</div>
            </div>
            <div className={"flex account-status margin-sides-10 status-"+ (payments===registered ? 1 : 5 )}>
                <div className="icon">{payments===registered ? <CheckIcon/> : <WarningIcon/> }</div>
                <div className="label">Paiements : {payments}</div>
            </div>
            <div className={"flex account-status margin-sides-10 status-2"}>
                <div className="icon"><FireIcon/></div>
                <div className="label">Collecté : {collected}</div>
            </div>
        </span>
    </span>
    <div className="flex flex-horizontal flex-right" style={{gap: 6}}>
        {children}
    </div>
</div>
    )
}
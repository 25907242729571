import { useState, useEffect } from "react"
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import FullPageLoading from 'components/FullPageLoading'

import Header from "components/HomeHeader"
import HomeBG from 'assets/imgs/home-bg.jpg'
// TODO : adapt to new text input
import TextInput from 'components/Inputs/OldTextInput'
import AuthService from 'core/services/AuthService'

const LoginPage = props => {
	const [error, setError] = useState()
	const [isLoading, setIsLoading] = useState(false)

	const [matricule, setMatricule] = useState("")
	const [password, setPassword] = useState("")
	const [redirect, setRedirect] = useState("/dashboard")

	const updateMatricule = (value) => {
		const indexDash = value.indexOf("-")
		setError(null)
		let hint = document.getElementById("hint")
		value = value.normalize('NFKD').replace(/[\u0300-\u036f]/g, '').replace(/[^A-Z0-9-]/gi, '').replace(/\W/g, '').replace('\'', '').replace(' ', '').toUpperCase()

		// Put the dash back if it was there
		if (indexDash !== -1) {
			value = value.substring(0, indexDash) + "-" + value.substring(indexDash, value.length)
		}

		switch (value.length) {
			case 0:
			case 1:
			case 2:
			case 3:
			case 4:
			case 5:
			case 6:
				value.match(/^[A-Z]+$/) ? setMatricule(value) : setMatricule(value.substring(0, value.length - 1))
				break
			case 7:
			case 8:
				value.substring(0, 6).match(/^[A-Z]+$/) 
					&& value.substring(6, 8).match(/^[0-9]+$/)  ? setMatricule(value) : setMatricule(value.substring(0, value.length - 1))
				break
			case 9:
				value.substring(0, 6).match(/^[A-Z]+$/) 
					&& value.substring(6, 8).match(/^[0-9]+$/) 
					&& value[8] === "-" ? setMatricule(value) : setMatricule(value.substring(0, value.length - 1))
				break
			case 10:
				value.substring(0, 6).match(/^[A-Z]+$/) && value.substring(6, 8).match(/^[0-9]+$/) && value[8] === "-" 
					&& !isNaN(value[9]) ? setMatricule(value) : setMatricule(value.substring(0, value.length - 1))
				break
			default:
				break
		}

		switch (value.length) {
			case 0:
			case 1:
			case 2:
				hint.innerText = "Commencez par entrer les 3 premières lettres de votre prénom (ou de celui de votre enfant)"
				break
			case 3:
			case 4:
			case 5:
				hint.innerText = "Entrez les 3 premières lettres de votre nom"
				break
			case 6:
			case 7:
				hint.innerText = "Entrez les 2 derniers chiffres de votre année de naissance"
				break
			case 8:
			default:
				hint.innerText = ""
				break
		}
	}


	const handleSubmit = e => {
		e.preventDefault()

    	if ( !matricule || !password ) {
			return toast.error("Veuillez remplir tous les champs")
		}
		if ( !(matricule.length === 8 || matricule.length === 10) ) {
			return setError(["Le matricule doit contenir 8 caractères"])
		}

		setIsLoading(true)

		AuthService.login(matricule, password).then(value => {

			setIsLoading(false)
			props.history.push(redirect)

		}).catch(err => {
			setIsLoading(false)
			toast.error(err.message)
		})
	}
	
	// If user is already logged in, refresh token and redirect to dashboard
	useEffect(() => {
		let isMounted = true

		const redirectQuery = new URLSearchParams(window.location.search).get("redirect")
		if(redirectQuery) setRedirect(redirectQuery)

		setIsLoading(true)
		AuthService.refreshToken().then(value => {
			props.history.push(redirect)
		}).catch(err => {
			localStorage.removeItem("refresh_token")
			console.log(err)
		}).finally(() => {
			if (isMounted) setIsLoading(false)
		})
		return () => { isMounted = false }
	}, [props.history, redirect])

	return (
		<>
			<Header />

			<FullPageLoading show={isLoading} />

			<div className="home-page small-form" style={{ backgroundImage: "url('" + HomeBG + "')"}}>

				<div className="full-page-card">

					<div className="blue-title">Connexion</div>

					<div className="card-content">

						<form className="login-form" onSubmit={handleSubmit}>

							<div className="infos-box" id="hint">
              Commencez par entrer les 3 premières lettres de votre prénom (ou de celui de votre enfant)
							</div>

							<TextInput className="ml-15" label="Matricule" name="matricule" value={matricule} onChange={e => updateMatricule(e.target.value)} errors={error}/>

							<TextInput type="password" className="ml-15" label="Mot de passe" name="pass" value={password} onChange={e => setPassword(e.target.value)} />

							<button className="btn" type="submit">Se connecter</button>

						</form>

						<div className="controls">

							<Link to="/request-password-reset" className="infos-text">
								J'ai oublié mon mot de passe (ou je n'en ai pas encore ...)
							</Link>

							<Link to="/contact" className="infos-text">
								J'ai besoin d'aide
							</Link>

						</div>

					</div>

				</div>

			</div>

		</>
	)
}

export default LoginPage
import React, { useRef } from "react"
import { Link } from 'react-router-dom'

import AuthService from "core/services/AuthService"

function DashHeader ({ paths}) {
	const user = useRef(AuthService.getCurrentUser())
	
	return (
		<>
			<div className="admin-header">

				<div className="flex flex-baseline">
					
					<div className="paths">
						{paths && paths.slice(0, -1).map((path, idx) => {
							return (
								<Link key={idx} to={path.url} className="path">{path.name} /&nbsp;</Link>
							)
						})}
						<Link key={paths.length} to={paths[paths.length - 1].url} className="path">{paths[paths.length - 1].name}</Link>
					</div>
				</div>

				<div className="flex flex-baseline">
					<div className="email">
						{user.current.email} {user.current.profiles.length !== 0 ? `[${user.current.profiles}]` : ""}
					</div>
					
				</div>
			
			</div>
		</>
	)
}

export default DashHeader
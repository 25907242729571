import React, { useCallback, useEffect, useState } from "react"
import axios from "axios"
import { useParams } from 'react-router'
import { toast } from "react-toastify"


import FullPageLoading from 'components/FullPageLoading'

import DashHeader from "components/common/DashHeader"
import DashSidebar from "components/common/Sidebar"
import Table from "components/Table";

import { Collapsible } from "components/utils/dev"

export default function Event({
    history,
    path,   // Note : path and history implicitly transmitted through the <DashRoute> component
}) {
    const { eID } = useParams()
    const from = path.split("/")[2]

    const { profiles } = JSON.parse(localStorage.getItem("user"))
    const downloadFMperm = profiles.some(elt => elt.startsWith("STAFF")) || profiles.some(elt => elt.startsWith("ADMIN")) || profiles.some(elt => elt.startsWith("DEV"))

    const [isLoading, setIsLoading] = useState(false)

    const [event, setEvent] = useState({})
    const [headers, setHeaders] = useState([])
    const [members, setMembers] = useState([])
    const [searchKeys, setSearchKeys] = useState([])
    const [filters, setFilters] = useState([])

    const loadEvent = useCallback(() => {
        setIsLoading(true)
        axios.get(`/api/events/${eID}`).then(res => {
            setEvent(res.data.event)
            setHeaders(res.data.headers);
            setMembers(res.data.members);
            setSearchKeys(res.data.searchKeys);
            setFilters(res.data.filters)
        }).catch(err => {
            if (err?.response?.data?.detail)
                toast.error(err.response.data.detail)
            else {
                toast.error("Erreur bizarre")
                console.log(err)
            }
        }).finally(() => {
            setIsLoading(false)
        })
    }, [eID])

    function getMemberSheet(matricule) {
        if (!matricule) return toast.error("Matricule invalide")
        setIsLoading(true)
        axios.get(`/api/members/${matricule}/memberSheet`, { 
            responseType: "blob" 
        }).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `fiche_membre_${matricule}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        }).catch(err => {
            toast.error("Erreur lors du téléchargement")
            console.log(err)
        }).finally(() => {
            setIsLoading(false)
        })
    }


    useEffect(() => {
        loadEvent();
    }, [loadEvent])

    return (
<>

<FullPageLoading show={isLoading} />

<div className="admin-page">

    <DashSidebar current={from} />

    <DashHeader paths={[
        {
            name: "Évènements",
            url: `/dashboard/${from}`
        },
        {
            name: event?.title,
            url: `/dashboard/${from}/${eID}`
        }

    ]} />

    <div className="admin-body">

        <div className="admin-section">
            Aperçu des inscriptions :
            {headers && members &&
            <Table
                uniqueID="listing"
                headers={headers}
                data={members}
                filters={filters}
                searchKeys={searchKeys}
                onItemClicked={member => history.push(`/dashboard/events/${eID}/list/${member.matricule}`)}
                buttonName="Fiche PDF"
                onButtonClicked={downloadFMperm ? member => getMemberSheet(member.matricule): null}
                buttonCondition={member => member.registered === "oui"}
            />}
        </div>

        {/* For development and debug ... */}
        <Collapsible header="event" data={event} />
        <Collapsible header="members" data={members} />
    </div>

</div>
</>
    )
}
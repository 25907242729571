import React, { useCallback, useEffect, useState } from "react"
import axios from "axios"
import { useParams } from 'react-router'
import { toast } from "react-toastify"
import { Link } from "react-router-dom"

import FullPageLoading from 'components/FullPageLoading'
import DashHeader from "components/common/DashHeader"
import DashSidebar from "components/common/Sidebar"
import { Collapsible } from "components/utils/dev"


function GroupList({
    eID,
    name="[..]ième Cie",
    companions=[], 
    chiefs=[],
}) {
    return (
<section className="member-basic">
    <div className="change">
        <div style={{"marginBottom": "10px"}}><strong>{name}</strong></div>
        Jeunes ({companions.length}) :
        <ul> {companions.map(m => 
            <li key={m.matricule}> 
                <Link to={`/dashboard/events/${eID}/presences/${m.matricule}`}>{m.name}</Link>
            </li>)} 
        </ul>
        Moniteurs ({chiefs.length}) :
        <ul> {chiefs.map(m => 
            <li key={m.matricule}> 
                <Link to={`/dashboard/events/${eID}/presences/${m.matricule}`}>{m.name}</Link>
            </li>)} 
        </ul>
    </div>
</section>
    )
}


export default function PresenceEvent({
    history,
    path,   // Note : path and history implicitly transmitted through the <DashRoute> component
}) {
    const { eID } = useParams()
    const from = path.split("/")[2]

    const [isLoading, setIsLoading] = useState(false)

    const [event, setEvent] = useState({})
    const [lists, setLists] = useState([])

    const loadEvent = useCallback(() => {
        setIsLoading(true)
        axios.get(`/api/events/presence/${eID}`).then(res => {
            setEvent(res.data.event)
            setLists(res.data.lists);
        }).catch(err => {
            if (err?.response?.data?.detail)
                toast.error(err.response.data.detail)
            else {
                toast.error("Erreur bizarre")
                console.log(err)
            }
        }).finally(() => {
            setIsLoading(false)
        })
    }, [eID])

    useEffect(() => {
        loadEvent();
    }, [loadEvent])

    return (
<>

<FullPageLoading show={isLoading} />

<div className="admin-page">

    <DashSidebar current={from} />

    <DashHeader paths={[
        {
            name: event?.title,
            url: `/dashboard/${from}/${eID}`
        },
        {
            name: "Présence",
            url: `/dashboard/${from}/${eID}/presences`
        }
    ]} />

    <div className="admin-body">

    <div className="member">

        <div className="admin-section">
            Listes de présence :
        </div>

        {lists.map(cie => 
            <GroupList
                eID={eID}
                key={cie.id}
                name={cie.name}
                companions={cie.companions}
                chiefs={cie.chiefs}
            />
        )}
    </div>

    {/* For development and debug ... */}
    <Collapsible header="event" data={event} />
    <Collapsible header="lists" data={lists} />
    </div>

</div>
</>
    )
}
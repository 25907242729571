import { useEffect, useMemo, useState } from "react"

import TextInput from "components/Inputs/OldTextInput";
import MultipleSelectInput from "components/Inputs/MultipleSelect";
import IconButton from "components/IconButton";
import { ReactComponent as ExportIcon } from 'assets/icons/export2.svg'
import { FilterIcon, MdRefresh } from "icons";

export default function Table({
    uniqueID, 
    headers, 
    data,
    dataName="membre",
    filters=[],
    searchKeys=[],
    onItemClicked,
    onButtonClicked,
    buttonName=" ",
    buttonCondition=null,
}) {

	const [orderBy, setOrderBy] = useState({})

    const [search, setSearch] = useState("")
    function findInList(search, terms) {
        search = search.toLowerCase()
        for (const term of terms) {
            if (term && String(term).toLowerCase().includes(search)) return true
        }
        return false
    }

    if (onButtonClicked) {
        headers = [...headers, {label: buttonName, key: "action-btn"}]
    }

    const [showOptions, setShowOptions] = useState("")
    const [filterLists, setFilterLists] = useState({})
    function toggleOptions(event, key) {
        event.stopPropagation()
        if (showOptions === key) {
            setShowOptions("")
        } else {
            setShowOptions(key)
        }
    }
    function selectFilters(key, value) {
        const newFilterLists = {...filterLists, [key]: value}
        sessionStorage.setItem(`${uniqueID}-filters`, JSON.stringify({
            filterLists: newFilterLists, 
            page: window.location.pathname
        }))
        setFilterLists(newFilterLists)
    }

    const filteredData = data.filter(row => {
        for (const {key} of filters) {
            if (filterLists[key] && filterLists[key].length > 0) {
                if (!filterLists[key].includes(row[key])) return false
            }
        }
        if (search) {
            let terms = searchKeys.map(key => row[key])
            if (!findInList(search, terms))
                return false
        }
        return true
    })

	// Check the session storage to see if a sort has been applied to this page
	useEffect(() => {
		const sort = sessionStorage.getItem(`${uniqueID}-sort`)
        const sortData = JSON.parse(sort)
		if (sortData) {			
			if(sortData.page === window.location.pathname)
				setOrderBy(sortData)
		}
        const filtersStr = sessionStorage.getItem(`${uniqueID}-filters`)
        const current = JSON.parse(filtersStr)
        if (current) {
            if (current.page === window.location.pathname) {
                setFilterLists(current.filterLists)
            }
        }
        // When clicking out of the MultipleSelect choices, simply close all
        function closeOptions(event) {
			if (!event.target.closest(".multiple-select") && !["path", "svg"].includes(event.target.nodeName.toLowerCase())) 
                setShowOptions("")
		}
		window.addEventListener("click", closeOptions)
		return function () {
			window.removeEventListener("click", closeOptions)
		}
	}, [uniqueID])

	function handleClickedHeader(e, header) {
		if(!header.key) return
		// Save the sort data in a session storage, so that when the user comes back to the page, the sort is still applied
		sessionStorage.setItem(
            `${uniqueID}-sort`, 
            JSON.stringify({
                key: header.key, 
                order: orderBy.order * -1, 
                page: window.location.pathname
            }))
		let newOrder = {...orderBy}
		if (orderBy.key === header.key) {
			newOrder = {...orderBy, order: orderBy.order * -1}
		} else {
			newOrder = {key: header.key, order: 1}
			if(header.sort)
				newOrder.sort = header.sort
		}
		setOrderBy(newOrder)
	}

    function resetFilters() {
        sessionStorage.setItem(`${uniqueID}-sort`, null)
        setOrderBy({})
        sessionStorage.setItem(`${uniqueID}-filters`, null)
        setFilterLists({})
        setShowOptions("")
    }

	function handleClickedItem(e, itemData) {
		if (onItemClicked) onItemClicked(itemData)
	}

	const sortedData = useMemo(() => {
		if(orderBy.key) {

			if(orderBy.sort)
				return orderBy.sort(filteredData, orderBy.order)
			try {
				return filteredData.sort((a, b) => (b[orderBy.key].toLowerCase() > a[orderBy.key].toLowerCase() ? -1 : 1) * orderBy.order)
			} catch(e) {
				return filteredData.sort((a, b) => (b[orderBy.key] > a[orderBy.key] ? -1 : 1) * orderBy.order)
			}
		}
		return filteredData
		
	}, [filteredData, orderBy])

	return (
<>
{(searchKeys.length > 0 || filters.length > 0) &&
<div className="user-action">
    {searchKeys.length > 0 &&
    <TextInput 
        label="Rechercher" 
        value={search ?? ""}
        baselineNoMargin
        onChange={(event => setSearch(event.target.value))} />
    }
    <div> {filteredData.length} {dataName}{filteredData.length > 1 ? "s" : ""}</div>
    {filters.map(({key, label, options}) => {
        if (options.length < 2) return null;
        return (
            <div key={`filter-${key}`}>
                <IconButton 
                    icon={<FilterIcon />} 
                    label={label}
                    onClick={(e) => toggleOptions(e, key)}
                    active={filterLists[key] && filterLists[key].length > 0}
                />
                <MultipleSelectInput
                    show={showOptions === key}
                    options={options}
                    value={filterLists[key] ?? undefined}
                    onChange={e => {selectFilters(key, e.target.value)}}
                />
            </div>
        )
    })}
    <div><IconButton  icon={<MdRefresh />} onClick={resetFilters} /></div>
    
</div>}

<table className="table">
<thead style={{position: "sticky", top: "55px", backgroundColor: "white", zIndex: 1}}>
    <tr className="table-header">
        {headers.map((header, idx) => {
            return (
                <th key={idx} onClick={(e) => handleClickedHeader(e, header)} className="table-header-item">
                    <span className={(orderBy.key === header.key ? (orderBy.order === 1 ? "arrow-up" : "arrow-down") : "")}>{header.label}</span>
                </th>
            )
        })}

    </tr>
</thead>

<tbody className="table-body">

    {sortedData.map((data, idx) => {
        return (
            <tr key={idx} onClick={(e) => handleClickedItem(e, data)} className="table-row  pointer">

                {headers.map(header => {
                    if(!header.formatFunc)
                        header.formatFunc = head => head

                    return (
                        <td key={header.key} className="table-cell">
                            {(header.key === "action-btn" && (buttonCondition ? buttonCondition(data) : true)) ?
                                <div style={{display: "flex", justifyContent: "center"}}>
                                <button 
                                    className="btn" style={{margin: 0, fontSize: 0, textAlign: "center"}} 
                                    onClick={e => { e.stopPropagation(); onButtonClicked(data) }}>
                                    <ExportIcon style={{ fill: "white" }} />
                                </button>
                                </div>
                                : 
                                <span>{header.formatFunc(data[header.key], data)}</span>
                            }
                            
                        </td>
                    )
                })}

            </tr>
        )
    })}

</tbody>

</table>
</>
	)
}